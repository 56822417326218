<template>
  <div>
    <Header />
    <div class="page-title">
      <div class="container">
        <div class="title text-black">Nosso Time</div>
      </div>
    </div>

    <section class="time pt-1-mobile">
      <div class="container">
        <div class="block desktop6 tablet6 mobile12 pr-1-desktop pr-1-tablet">
          <div class="flex-between">
            <div class="input-item">
              <span>Selecione um estado</span>
              <select @change="filterCities" v-model="selectedState">
                <option
                  v-for="(state, index) in states"
                  :key="index"
                  :value="state"
                >
                  {{ state }}
                </option>
              </select>
            </div>

            <transition enter-active-class="animated fadeIn">
              <div v-show="selectedState" class="input-item">
                <span>Selecione uma cidade</span>
                <select @change="filterContacts" v-model="selectedCity">
                  <option
                    v-for="(city, index) in cities"
                    :key="index"
                    :value="city"
                  >
                    {{ city }}
                  </option>
                </select>
              </div>
            </transition>
          </div>

          <transition enter-active-class="animated fadeInLeft">
            <div class="contacts-holder">
              <div v-for="(contact, index) in contacts" :key="index">
                <div class="contact-item" v-if="contact.tipo">
                  <div class="before-title contact">{{ contact.tipo }}</div>
                  <div class="name">{{ contact.nome }}</div>
                  <a :href="`mailto:${contact.email}`" class="text-desc">{{
                    contact.email
                  }}</a>
                  <a :href="`tel:${contact.fone}`" class="text-desc">{{
                    contact.fone
                  }}</a>
                </div>
                <div class="contact-item" v-if="contact.tipo2">
                  <div class="before-title contact">{{ contact.tipo2 }}</div>
                  <div class="name">{{ contact.nome2 }}</div>
                  <a :href="`mailto:${contact.email2}`" class="text-desc">{{
                    contact.email2
                  }}</a>
                  <a :href="`tel:${contact.fone2}`" class="text-desc">{{
                    contact.fone2
                  }}</a>
                </div>
                <div class="contact-item" v-if="contact.tipo3">
                  <div class="before-title contact">{{ contact.tipo3 }}</div>
                  <div class="name">{{ contact.nome3 }}</div>
                  <a :href="`mailto:${contact.email3}`" class="text-desc">{{
                    contact.email3
                  }}</a>
                  <a :href="`tel:${contact.fone3}`" class="text-desc">{{
                    contact.fone3
                  }}</a>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="block desktop6 tablet6 mobile12 mt-2-mobile">
          <img
            class="map"
            src="../assets/images/mapatime.png"
            alt="mapa do time"
          />
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "NossoTime",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      states: [],
      cities: [],
      contacts: [],
      selectedState: null,
      selectedCity: null,
    };
  },
  created() {
    let localesScript = document.createElement("script");
    localesScript.setAttribute(
      "src",
      `${window.location.origin}/nosso-time/cidades.js`
    );
    document.head.appendChild(localesScript);
    localesScript.onload = () => {
      this.states = [...new Set(Locales.map((el) => el.uf))];
      this.loadCitiesScript();
    };
  },
  methods: {
    loadCitiesScript() {
      let PeopleScript = document.createElement("script");
      PeopleScript.setAttribute(
        "src",
        `${window.location.origin}/nosso-time/data.js`
      );
      document.head.appendChild(PeopleScript);
    },
    filterCities() {
      this.selectedCity = null;
      this.contacts = [];
      let cities = Locales.filter((el) => el.uf === this.selectedState);
      this.cities = cities.map((el) => el.cidade);
    },
    filterContacts() {
      this.contacts = People.filter(
        (el) => el.uf === this.selectedState && el.cidade === this.selectedCity
      );
    },
  },
};
</script>
<style lang="scss" scoped>
section.time .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contact-item {
  position: relative;
  padding: 12px 15px;
  border-left: 2px solid var(--yellow1);
  background-color: #f5f5f5;
  margin-top: 15px;
  .before-title.contact {
    color: var(--primary);
    margin-bottom: 6px;
  }
  .name {
    position: relative;
    font-family: fontBold;
    font-size: 1.3em;
  }
  .text-desc {
    display: block;
  }
}

img.map {
  max-width: 100%;
}

@media screen and(max-width: 720px) {
}

@media only screen and (min-width: 721px) and (max-width: 1139px) {
}
</style>
